import * as React from 'react';

import { propertyClient } from '../lib/dashboard/property';
import { reservationClient } from '../lib/dashboard/reservation';
import { logger } from '../lib/default-logger';
import { useUser } from '../hooks/use-user';

export const PropertyContext = React.createContext(undefined);

export function PropertyProvider({ children }){
  const [state, setState] = React.useState({
    properties: null,
    property: null,
    error: null,
    isLoading: true,
  });

  const { user } = useUser();

  const userSub = user?.["custom:tenant_id"]?? ""

  async function listProperties() {
    const { data, error } = await propertyClient.list(userSub)

    if(error)
    {
      logger.error(error)
      return { data: null, error: error };
    }

    setState((prev) => ({ ...prev, properties: data }));

    return { data: data, error: error }
  }

  async function loadProperty(property_id ) {
    const { data, error } = await propertyClient.load(userSub, property_id)

    if(error)
    {
      logger.error(error)
      return { data: null, error: error };
    }

    setState((prev) => ({ ...prev, property: data }));

    return { data: data, error: error }
  }

  async function createProperty(property_name) {
    const { data, error } = await propertyClient.create(userSub, property_name)

    if(error)
    {
      logger.error(error)
      return { data: null, error: error };
    }

    setState((prev) => ({ ...prev, property: data }));

    return { data: data, error: error }
  }

  async function updateProperty(property_id, property_name) {
    const { data, error } = await propertyClient.update(userSub, property_id, property_name)

    if(error)
    {
      logger.error(error)
      return { data: null, error: error };
    }

    setState((prev) => ({ ...prev, property: data }));

    return { data: data, error: error }
  }

  async function listReservations() {
    const { data, error } = await reservationClient.list(userSub)

    if(error)
    {
      logger.error(error)
      return { data: null, error: error };
    }

    setState((prev) => ({ ...prev, reservations: data }));

    return { data: data, error: error }
  }

  async function loadReservation(reservation_id ) {
    const { data, error } = await reservationClient.load(userSub, reservation_id)

    if(error)
    {
      logger.error(error)
      return { data: null, error: error };
    }

    setState((prev) => ({ ...prev, reservation: data }));

    return { data: data, error: error }
  }


  async function createReservation(reservation_id, number_of_guests, check_in_date, check_out_date, property) {
    const { data, error } = await reservationClient.create(userSub, reservation_id, number_of_guests, check_in_date, check_out_date, property)

    if(error)
    {
      logger.error(error)
      return { data: null, error: error };
    }

    setState((prev) => ({ ...prev, reservation: data }));

    return { data: data, error: error }
  }

  async function updateReservation(reservation_id, number_of_guests, check_in_date, check_out_date, property) {
    const { data, error } = await reservationClient.update(userSub, reservation_id, number_of_guests, check_in_date, check_out_date, property)

    if(error)
    {
      logger.error(error)
      return { data: null, error: error };
    }

    setState((prev) => ({ ...prev, reservation: data }));

    return { data: data, error: error }
  }

  return <PropertyContext.Provider value={{ ...state, 
    listProperties: listProperties,
    loadProperty: loadProperty,
    createProperty: createProperty,
    updateProperty: updateProperty,
    listReservations: listReservations,
    loadReservation: loadReservation,
    createReservation: createReservation,
    updateReservation: updateReservation
 }}>{children}</PropertyContext.Provider>;
}

export const PropertyConsumer = PropertyContext.Consumer;
