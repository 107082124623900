import { BrowserRouter, Routes, Route, Outlet, Link } from "react-router-dom";
import Dashboard from './pages/app/page'
import DashboardLayout from './pages/app/layout'
import Reservations from './pages/app/reservations/page'
import ReservationCreate from './pages/app/reservations/create/page'
import ReservationEdit from './pages/app/reservations/edit/page'

import Properties from './pages/app/properties/page'
import PropertyCreate from './pages/app/properties/create/page'
import PropertyEdit from './pages/app/properties/edit/page'

import Integrations from './pages/app/integrations/page'

import SignIn from './pages/auth/sign-in/page'
import SignUp from './pages/auth/sign-up/page'
import CheckIn from './pages/check-in/page'
import ConfirmSignUp from './pages/auth/confirm-sign-up/page'
import AuthLayout from './pages/auth/layout'

import { UserProvider } from './contexts/user-context';
import { ThemeProvider } from './components/core/theme-provider/theme-provider';
import { LocalizationProvider } from './components/core/localization-provider';

import './styles/global.css';

function App() {
  return (
    <BrowserRouter>
      <LocalizationProvider>
        <UserProvider>
          <ThemeProvider>
            <Routes>
              <Route path="/check-in/" element={<AuthLayout />}>
                <Route index element={<CheckIn />} />
              </Route>

              <Route path="/auth/" element={<AuthLayout />}>
                <Route path="sign-in" element={<SignIn />} />
                <Route path="sign-up" element={<SignUp />} />
                <Route path="confirm-sign-up" element={<ConfirmSignUp />} />
                <Route path="*" element={<NoMatch />} />
              </Route>
              <Route path="/app/" element={<DashboardLayout />}>
                <Route path="dashboard" index element={<Dashboard />} />
                <Route path="reservations" element={<Reservations />} />
                <Route path="reservations/create" element={<ReservationCreate />} />
                <Route path="reservations/:id" element={<ReservationEdit />} />
                <Route path="properties" element={<Properties />} />
                <Route path="properties/create" element={<PropertyCreate />} />
                <Route path="properties/:id" element={<PropertyEdit />} />
                <Route path="integrations" element={<Integrations />} />

                <Route path="*" element={<NoMatch />} />
              </Route>
            </Routes>
          </ThemeProvider>
        </UserProvider>
      </LocalizationProvider>
    </BrowserRouter>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default App;
