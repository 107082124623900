import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Controller, useForm } from 'react-hook-form';
import { z as zod } from 'zod';
import LoadingButton from '@mui/lab/LoadingButton';
import LockIcon from '@mui/icons-material/LockOpen';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { paths } from '../../paths';
import { useUser } from '../../hooks/use-user';

import axios from 'axios';

const schema = zod.object({
  firstName: zod.string().min(1, { message: 'First name is required' }),
  lastName: zod.string().min(1, { message: 'Last name is required' }),
  email: zod.string().min(1, { message: 'Email is required' }).email(),
  password: zod.string().min(6, { message: 'Password should be at least 6 characters' }),
  terms: zod.boolean().refine((value) => value, 'You must accept the terms and conditions'),
});

const defaultValues = { firstName: 'Jose', lastName: 'Luiz', email: 'albernazj93.uclock.io+airbnbuilding@gmail.com', password: '123123123', terms: false };

export function CheckInForm() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { checkSession, signUp } = useUser();

  const [isPending, setIsPending] = React.useState(false);
  const [guests, setGuests] = React.useState();
  const [reservation, setReservation] = React.useState();

  const [openDialog, setOpenDialog] = React.useState(false);

  const maxOpenGate = 3;

  const [loadingReservation, setLoadingReservation] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {    
    loadReservation(searchParams.get('tenant'), searchParams.get('code'))
  }, [])

  const handleCheckIn = (event) => {
    event.preventDefault()

    const validForms = guests?.every(guest =>
      guest.name && !guest.nameError &&
      guest.rg && !guest.rgError &&
      guest.cpf && !guest.cpfError
    );

    if (validForms) {
      setLoading(true)
      setIsPending(true);

      axios.put('https://api.dev.airbnbuilding.com/check-in', {
        tenant_id: searchParams.get('tenant'),
        reservation_id: searchParams.get('code'),
        guests: guests?.map(guest => ({
          name: guest.name,
          documents: [
            { type: "RG", value: guest.rg },
            { type: "CPF", value: guest.cpf }
          ]
        }))
      })
        .then(res => {
          loadReservation(searchParams.get('tenant'), searchParams.get('code'))
        })
        .catch(err => console.log(err))
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const handleUnlock = () => {
    setLoading(true)

    axios.post('https://api.dev.airbnbuilding.com/devices/ewelink/unlock', {
      tenant_id: searchParams.get('tenant'),
      reservation_id: searchParams.get('code')
    })
      .then(res => {
        setReservation((prevState) => ({
          ...prevState,
          unlocked: res.data.unlocked
        }))
      })
      .catch(err => console.log(err))
      .finally(() => {
        setLoading(false)
      })
  }

  const loadReservation = (tenant_id, reservation_id) => {
    setLoadingReservation(true)

    axios.get('https://api.dev.airbnbuilding.com/check-in/' + tenant_id, {
      params: {
        tenant_id: tenant_id,
        reservation_id: reservation_id
      }
    })
      .then(res => {
        setReservation(res.data)

        console.log(res.data)

        const initialGuests = Array(res.data.number_of_guests).fill(null).map(() => ({
          name: '',
          rg: '',
          cpf: '',
          nameError: '',
          rgError: '',
          cpfError: ''
        }));
  
        setGuests(initialGuests);
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setLoadingReservation(false)
      })
  }


  const handleInputChange = (index, field, value) => {
    const newGuests = [...guests];
    newGuests[index][field] = value;

    if (field === 'name') {
      newGuests[index].nameError = nameValidator(value);
    }
    if (field === 'rg' || field === 'cpf') {
      newGuests[index][field + 'Error'] = nationalDocumentsValidator(value);
    }

    setGuests(newGuests);
  };

  const nameValidator = (value) => {
    if (value.length < 3) return "Nome deve ter pelo menos 3 letras";
    if (value.split(' ').length < 2) return "Nome completo deve ter pelo menos 2 nomes";
    if (!/^[a-zA-Z ]+$/.test(value))
      return "Nome deve conter apenas letras e espaços";
    return false;
  };

  const nationalDocumentsValidator = (value) => {
    if (value.length < 5) return "Documento deve ter pelo menos 5 números";
    return false;
  }

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ resolver: zodResolver(schema) });

  const onSubmit = React.useCallback(
    async (values) => {
      setIsPending(true);

      const { signUpStep, error } = await signUp(values);

      if (error) {
        setError('root', { type: 'server', message: error });
        setIsPending(false);
        return;
      }

      if (signUpStep === 'CONFIRM_SIGN_UP'){
        navigate(paths.auth.confirmSignUp)
      }
    },
    [checkSession, navigate, setError]
  );

  const generateGuestsForm = () => {
    return guests?.map((guest, index) => (
      <>
        <Stack>
          <Typography fontWeight={"bold"} marginTop={2} align='left'>
            Hóspede {index + 1}
          </Typography>
          <Stack>
            <TextField
              margin="normal"
              error={Boolean(guest.nameError)}
              helperText={guest.nameError}
              required
              fullWidth
              id={`name-${index}`}
              label="Nome completo"
              name="name"
              value={guest.name}
              onChange={e => handleInputChange(index, 'name', e.target.value)}
              onBlur={e => handleInputChange(index, 'name', e.target.value)}
            />
          </Stack>
          <Stack>
            <TextField
              margin="normal"
              error={Boolean(guest.rgError)}
              helperText={guest.rgError}
              required
              fullWidth
              name="rg"
              label="RG"
              id={`rg-${index}`}
              value={guest.rg}
              onChange={e => handleInputChange(index, 'rg', e.target.value)}
              onBlur={e => handleInputChange(index, 'rg', e.target.value)}
            />
          </Stack>
          <Stack>
            <TextField
              margin="normal"
              error={Boolean(guest.cpfError)}
              helperText={guest.cpfError}
              required
              fullWidth
              name="cpf"
              label="CPF"
              id={`cpf-${index}`}
              value={guest.cpf}
              onChange={e => handleInputChange(index, 'cpf', e.target.value)}
              onBlur={e => handleInputChange(index, 'cpf', e.target.value)}
            />
          </Stack>
        </Stack>
      </>
    ));
  };


  return (
    <Stack spacing={3}>
      <Stack spacing={1}>
        <Typography variant="h4">Check in</Typography>
        <Typography color="text.secondary" variant="body2">
          Procedimento obrigatório de check-in para autorização de entrada no condomínio.
          {/* <Link component={RouterLink} href={paths.auth.signIn} underline="hover" variant="subtitle2">
            Sign in
          </Link> */}
        </Typography>

        {loadingReservation ?
          // CARREGANDO RESERVA
          <Typography marginTop={2}>
            Carregando sua reserva...
          </Typography>
          : reservation == null ?
            // RESERVA NAO EXISTENTE
            <Typography marginTop={2}>
              Código de reserva não localizado ou reserva concluida!
            </Typography>
            : reservation != null && reservation.guests?.length > 0 ?
              <>
                <Typography variant="h5" marginTop={2}>
                  Check-in realizado com sucesso!
                </Typography>
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  fullWidth
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => { setOpenDialog(true) }}
                  disabled={reservation.unlocked >= maxOpenGate}
                  endIcon={<LockIcon />}
                >
                  Abrir portão
                </LoadingButton>
                <Dialog
                  open={openDialog}
                  onClose={() => { setOpenDialog(false) }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Tem certeza que deseja acionar a abertura do portão?"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Utilizado {reservation.unlocked} vezes do total de {maxOpenGate} tentativas.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => { setOpenDialog(false) }}>Cancelar</Button>
                    <Button onClick={() => { handleUnlock(); setOpenDialog(false) }} autoFocus>
                      ABRIR PORTÃO
                    </Button>
                  </DialogActions>
                </Dialog>
                <Alert severity="warning">
                  Utilize apenas quando for abrir o portão pela primeira vez. <br></br>

                  Utilizado {reservation.unlocked} vezes do total de {maxOpenGate} tentativas.
                </Alert>
              </>
              :
              // CHECK PENDENTE
              <>
                {generateGuestsForm()}

                <LoadingButton
                  loading={loading}
                  variant="contained"
                  type="submit"
                  fullWidth
                  sx={{ mt: 3, mb: 2 }}
                  onClick={handleCheckIn}
                >
                  Check-in
                </LoadingButton>

              </>
        }
      </Stack>
    </Stack>
  );
}
