'use client';

import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs, { Dayjs } from 'dayjs';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Unstable_Grid2';
import { z as zod } from 'zod';
import { Controller, useForm } from 'react-hook-form';
import FormHelperText from '@mui/material/FormHelperText';

import { useProperty } from '../../../../hooks/use-property';
import { paths } from '../../../../paths';

const schema = zod.object({
  reservation_id: zod.string().min(1, { message: 'Reservation id is required' }),
  check_in_date: zod.any({ message: 'Check-in date is required' }),
  check_out_date: zod.any({ message: 'Check-out date is required' }),
  number_of_guests: zod.coerce.number().min(1, { message: 'Number of guests is required' }),
  property_id: zod.string().min(1, { message: 'Enter a valid property' }).or(zod.literal('')),
});

export function ReservationDetailsForm({
  reservation
}) {
  const navigate = useNavigate();

  const { createReservation, updateReservation, listProperties } = useProperty();

  const [properties, setProperties] = React.useState()
  const [isPending, setIsPending] = React.useState(false);

  React.useEffect(() => {
    async function fetchData() {
      const { data, error } = await listProperties()

      const properties = await data.body.json()

      setProperties(properties)
    }
            
    fetchData()
  }, []);

  const initialValues = { 
    reservation_id: reservation?.reservation_id?? "",
    check_in_date: dayjs(reservation?.check_in_date?? Date()),
    check_out_date: dayjs(reservation?.check_out_date?? Date()),
    number_of_guests: reservation?.number_of_guests?? 1,
    property_id: reservation?.property_detail?.property_id?? "",
  };

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ values: initialValues, resolver: zodResolver(schema) });

  const onSubmit = React.useCallback(
    async (values) => {
      setIsPending(true);

      const property = properties?.find((property) => property.property_id === values.property_id)

      const { data, error } = reservation ? 
        await updateReservation(reservation.reservation_id, values.number_of_guests, values.check_in_date, values.check_out_date, property)
      : 
        await createReservation(values.reservation_id, values.number_of_guests, values.check_in_date, values.check_out_date, property) 
        
      if (error) {
        setError('root', { type: 'server', message: error });
        setIsPending(false);
        return;
      }

      navigate(paths.app.reservations)
    },
    [reservation, properties, navigate, setError]
  );


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardHeader subheader="The information can be edited" title="Reservation" />
        <Divider />
        <CardContent>
          <Grid container spacing={3} sx={{ maxWidth: 'sm' }}>
            <Grid md={12} xs={12}>
              <Controller
                control={control}
                name="reservation_id"
                render={({ field }) => (
                  <FormControl fullWidth error={Boolean(errors.reservation_id)}>
                    <InputLabel>Reservation ID *</InputLabel>
                    <OutlinedInput {...field} label="Reservation name"/>
                    {errors.reservation_id ? <FormHelperText>{errors.reservation_id.message}</FormHelperText> : null}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid md={6} xs={12}>
              <Controller
                control={control}
                name="check_in_date"
                render={({ field }) => (
                  <FormControl fullWidth error={Boolean(errors.check_in_date)}>
                    <DatePicker
                      {...field} 
                      label="Check-in Date *"
                      value={field.value}
                      inputRef={field.ref}
                      onChange={(date) => {
                        field.onChange(date);
                      }}
                      format="DD/MM/YYYY"
                      slotProps={{
                        textField: {
                          helperText: 'DD/MM/YYYY',
                        },
                      }}
                    />
                    {errors.check_in_date ? <FormHelperText>{String(errors.check_in_date.message)}</FormHelperText> : null}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid md={6} xs={12}>
              <Controller
                control={control}
                name="check_out_date"
                render={({ field }) => (
                  <FormControl fullWidth error={Boolean(errors.check_out_date)}>
                     <DatePicker
                      {...field} 
                      label="Check-out Date *"
                      value={field.value}
                      inputRef={field.ref}
                      onChange={(date) => {
                        field.onChange(date);
                      }}
                      format="DD/MM/YYYY"
                      slotProps={{
                        textField: {
                          helperText: 'DD/MM/YYYY',
                        },
                      }}
                    />
                    {errors.check_out_date ? <FormHelperText>{String(errors.check_out_date.message)}</FormHelperText> : null}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid md={6} xs={12}>
              <Controller
                control={control}
                name="number_of_guests"
                render={({ field }) => (
                  <FormControl fullWidth error={Boolean(errors.number_of_guests)}>
                    <InputLabel>Number of Guests *</InputLabel>
                    <OutlinedInput {...field} label="Number of Guests *" type="number" />
                    {errors.number_of_guests ? <FormHelperText>{errors.number_of_guests.message}</FormHelperText> : null}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid md={6} xs={12}>
              <Controller
                control={control}
                name="property_id"
                render={({ field }) => (
                  <FormControl fullWidth error={Boolean(errors.property_id)}>
                    <InputLabel>Property</InputLabel>
                    <Select {...field} defaultValue={initialValues.property_id} label="Property" name="state" variant="outlined">
                      {properties?.map((property) => (
                        <MenuItem key={property.property_id} value={property.property_id}>
                          {property.property_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
          {errors.root ? <Alert color="error">{errors.root.message}</Alert> : null}

        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button variant="contained" type="submit" >Save details</Button>
        </CardActions>
      </Card>
      
    </form>
  );
}
