'use client';

import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Unstable_Grid2';
import { z as zod } from 'zod';
import { Controller, useForm } from 'react-hook-form';
import FormHelperText from '@mui/material/FormHelperText';

import { useProperty } from '../../../../hooks/use-property';
import { paths } from '../../../../paths';

const schema = zod.object({
  property_name: zod.string().min(1, { message: 'Property name is required' }),
});

export function PropertyDetailsForm({
  property
}) {
  const navigate = useNavigate();

  const { createProperty, updateProperty } = useProperty();

  const [isPending, setIsPending] = React.useState(false);

  const initialValues = { 
    property_name: property?.property_name?? ""
  }

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ values: initialValues, resolver: zodResolver(schema) });

  const onSubmit = React.useCallback(
    async (values) => {
      setIsPending(true);

      const { data, error } = property ? 
        await updateProperty(property.property_id, values.property_name)
      : 
        await createProperty(values.property_name) 
        
      if (error) {
        setError('root', { type: 'server', message: error });
        setIsPending(false);
        return;
      }

      navigate(paths.app.properties)
    },
    [property, navigate, setError]
  );


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardHeader subheader="The information can be edited" title="Property" />
        <Divider />
        <CardContent>
          <Grid container spacing={3} sx={{ maxWidth: 'sm' }}>
            <Grid md={12} xs={12}>
              <Controller
                control={control}
                name="property_name"
                render={({ field }) => (
                  <FormControl fullWidth error={Boolean(errors.property_name)}>
                    <InputLabel>Property name</InputLabel>
                    <OutlinedInput {...field} label="Property name" />
                    {errors.property_name ? <FormHelperText>{errors.property_name.message}</FormHelperText> : null}
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
          {errors.root ? <Alert color="error">{errors.root.message}</Alert> : null}

        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button variant="contained" type="submit" >Save details</Button>
        </CardActions>
      </Card>
    </form>
  );
}
