'use client';

import { 
    ApiError,
  get, post, put } from "aws-amplify/api"
import { amplifyClient } from '../../lib/amplify';

amplifyClient.configure()

class PropertyClient {
  
  async list(tenant_id) {
    var getOutput = null
    var error = ''

    try {
         const getOperation = get({
          apiName: 'properties',
          path: '/',
          options: {
            queryParams: {
              tenant_id: tenant_id
            }
          }
        })

        getOutput = await getOperation.response

        console.log(getOutput)
    } catch (err) {
        console.log(err)
        error = String(err)
    }
    
    return { data: getOutput, error: error };
  }

  async load(tenant_id, property_id) {
    var getOutput = null
    var error = ''

    try {
         const getOperation = get({
          apiName: 'properties',
          path: '/{id}',
          options: {
            queryParams: {
              tenant_id: tenant_id,
              property_id: property_id
            },
           
          },

        })

        getOutput = await getOperation.response
    } catch (err) {
        console.log(err)
        error = String(err)
    }
    
    return { data: getOutput, error: error };
  }

  async create(tenant_id, property_name) {
    var output = null
    var error = ''

    try {
         const operation = post({
          apiName: 'properties',
          path: '/',
          options: {
            body: { 
              tenant_id: tenant_id, 
              property_name: property_name 
            }
          }
        })

        output = await operation.response
    } catch (err) {
        console.log(err)
        error = String(err)
    }
    
    return { data: output, error: error };
  }


  async update(tenant_id, property_id, property_name) {
    var output = null
    var error = ''

    try {
         const operation = put({
          apiName: 'properties',
          path: '/',
          options: {
            body: { 
              tenant_id: tenant_id, 
              property_id: property_id,
              property_name: property_name 
            }
          }
        })

        output = await operation.response
    } catch (err) {
        console.log(err)
        error = String(err)
    }
    
    return { data: output, error: error };
  }
}

export const propertyClient = new PropertyClient();
