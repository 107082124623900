import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Download as DownloadIcon } from '@phosphor-icons/react/dist/ssr/Download';
import { Plus as PlusIcon } from '@phosphor-icons/react/dist/ssr/Plus';
import { Upload as UploadIcon } from '@phosphor-icons/react/dist/ssr/Upload';
import dayjs from 'dayjs';
import Grid from '@mui/material/Unstable_Grid2';

import { useProperty } from '../../../hooks/use-property';

import { config } from '../../../config';
import { ReservationInfo } from '../../../components/app/reservation/components/reservation-info';
import { ReservationDetailsForm } from '../../../components/app/reservation/components/reservation-details-form';
import { ReservationGuests } from '../../../components/app/reservation/components/reservation-guests';

import { z as zod } from 'zod';

export const metadata = { title: `Edit reservation | Dashboard | ${config.site.name}` };

export function ReservationEdit({
  id = ""
}) {
    const { loadReservation: getReservation, listProperties } = useProperty();

    const [reservation, setReservation] = React.useState()

    React.useEffect(() => {
      async function fetchData() {
        const { data, error } = await getReservation(id)

        const reservation = (await data.body.json())[0]
        console.log(reservation)
        setReservation(reservation)
      }

      fetchData()
    }, []);

    return (
      <Stack spacing={3}>
      <div>
        <Typography variant="h4">Reservation</Typography>
      </div>
      <Grid container spacing={3}>
        <Grid lg={4} md={6} xs={12}>
          <ReservationInfo reservation={reservation} />
        </Grid>
        <Grid lg={8} md={6} xs={12}>
          <ReservationDetailsForm reservation={reservation} />
        </Grid>
        <Grid lg={12} md={12} xs={12}>
          <ReservationGuests guests={reservation?.guests} />
        </Grid>
      </Grid>
    </Stack>
  );
}

function applyPagination(rows, page, rowsPerPage) {
  return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}
